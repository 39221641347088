import FileActions from '../actions/FileActions';

const initialState = {
  allDownloadFiles: [],
  initiateDownload: false,
  deleteFile: false,
  downloadUrl: {
    url: null,
    fileName: '',
  },
};

export type Downloads = {
  allDownloadFiles: any;
  initiateDownload: boolean;
  deleteFile: boolean;
  downloadUrl: any;
};

export default (state: Downloads = initialState, action: any) => {
  switch (action.type) {
    case FileActions.MY_DOWNLOADS_LOADING:
      return {
        ...state,
        allDownloadFiles: state.allDownloadFiles,
      };

    case FileActions.MY_DOWNLOADS_SUCCESS:
      return {
        ...state,
        allDownloadFiles: action.data,
      };

    case FileActions.MY_DOWNLOADS_ERROR:
      return {
        ...state,
        allDownloadFiles: action.data,
      };

    case FileActions.INITIATE_DOWNLOAD_FILE: {
      return {
        ...state,
        initiateDownload: action.payload,
      };
    }

    case FileActions.DELETE_FILES_LOADING:
      return {
        ...state,
        deleteFile: false,
      };
    case FileActions.DELETE_FILES_SUCCESS:
      return {
        ...state,
        deleteFile: true,
      };
    case FileActions.DELETE_FILES_ERROR:
      return {
        ...state,
        deleteFile: false,
      };

    case FileActions.TOGGLE_DELETE_STATE:
      return {
        ...state,
        deleteFile: action.payload,
      };

    case FileActions.DOWNLOAD_FILE_LOADING: {
      const { data, fileName } = action;
      return {
        ...state,
        downloadUrl: { url: data, fileName },
      };
    }
    case FileActions.DOWNLOAD_FILE_SUCCESS: {
      const { data: url, fileName } = action;
      return {
        ...state,
        downloadUrl: { url, fileName },
      };
    }
    case FileActions.DOWNLOAD_FILE_ERROR: {
      const { data, fileName } = action;
      return {
        ...state,
        downloadUrl: { url: data, fileName },
      };
    }
    case FileActions.DOWNLOAD_FILE_DONE: {
      return {
        ...state,
        downloadUrl: { url: null, fileName: '' },
      };
    }

    default:
      return state;
  }
};
