import * as Sentry from '@sentry/react';
import checkWhichEnvironment from './utils/environment';

export const initSentry = () => {
  const environment = checkWhichEnvironment() || 'development';
  if (environment === 'staging' || environment === 'production') {
    Sentry.init({
      dsn: process.env.REACT_SENTRY_DSN,
      environment: environment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
