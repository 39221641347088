import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ExtraShortSkeletonCell,
  Tab,
  Tabs,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import {
  EnergyPro,
  EnergyPro2,
  ErrorIcon,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  determinePhaseDisplayAndSource,
  EnergyProWarning,
  energyProWarningToLabelMapping,
  genericTableSort,
  global,
  isDefined,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  FirmwareGatewayModel,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionStatus,
  OpacityIndex,
  PowerMeteringType,
  SortDirection,
  Vendor,
  InspectionJumpToRef,
  OpenFirmwareUpdateModalFunc,
} from '@energybox/react-ui-library/dist/types';
import InspectionTile, {
  getErrorOrWarningIconForField,
  InspectionHeader,
} from '../InspectionTile';
import TimestampTooltip from '../TimestampTooltip';
import InfoIconTooltip from '../InfoIconTooltip';
import {
  getId,
  getTitle,
  getDetailFields,
  getSummaryFields,
  doesItemContainErrorOrWarning,
  transformDhcpStatusValue,
  transformBusDeviceInfoValue,
  checkFieldsErrorsAndWarnings,
  getSensorsOrActuatorsSummaryFields,
  isVersionRequirementFulfilled,
  checkSensorErrorsAndWarnings,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { formatDecimalValue } from '../../../../utils/numbers';
import { Routes } from '../../../../routes';

import styles from './EnergyProTile.module.css';
import tileStyles from '../InspectionTile/InspectionTile.module.css';
import DeviceConnectionStatus from '../../../../components/DeviceConnectionStatus';
import { PHASE_LABEL } from '../../../Selects/SelectPhase';

type Props = {
  siteId: string;
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  jumpToRef: InspectionJumpToRef;
  canUpdateFirmware?: boolean;
  onFirmwareVersionLinkClick?: OpenFirmwareUpdateModalFunc;
  isEnergyPro2?: boolean;
};

type ProcessedSensorReading = {
  indexString: string;
  powerActive: number;
  powerReactive: number;
  current: number;
  powerFactor: number;
  voltage: number;
  sensorId: number;
  breakerId: number;
  breakerName: string;
  isMainBreaker: boolean;
  energyDeviceId: number;
  port: number;
};

const formatTableNumber = field => {
  if (
    (!field && field !== 0) ||
    field === undefined ||
    field === null ||
    isNaN(field)
  ) {
    return global.NOT_AVAILABLE;
  }
  return formatDecimalValue(field);
};

const getPortNumberFromIndex = (data: InspectionDataFieldsByKey) => {
  const matchNum = (data as any).index?.field?.match(/\d+/g);
  const portNum = matchNum ? parseInt(matchNum[matchNum.length - 1], 10) : -1;
  return portNum;
};

const skeletonCellContent = (rowIndex: OpacityIndex) => (
  <ExtraShortSkeletonCell opacityIndex={rowIndex} />
);

// Helper function to render table cell content with a link and tooltip
const renderTableCellContent = (
  field: InspectionDataField,
  formatField: (field: InspectionDataField) => string,
  siteId: string,
  link?: string,
  timestamp?: InspectionDataField,
  icon?: JSX.Element | null
) => {
  if (!field) return null;
  return (
    <div className={tileStyles.tableFieldValue}>
      {link ? (
        <Link to={link} target="_blank">
          {timestamp?.field ? (
            <TimestampTooltip
              timestamp={timestamp.field as string}
              siteId={siteId}
            >
              {formatField(field)}
            </TimestampTooltip>
          ) : (
            formatField(field)
          )}
        </Link>
      ) : (
        formatField(field)
      )}
      {icon}
    </div>
  );
};

const renderWarningOrErrorTooltip = (warnings: any[]) => {
  if (!warnings || warnings.length === 0) return null;
  const errorWarnings = warnings.filter(w => w.warning.startsWith('ERROR'));
  const hasError = errorWarnings.length > 0;
  return (
    <Tooltip
      content={
        hasError
          ? errorWarnings
              .map(
                w =>
                  energyProWarningToLabelMapping[w.warning] ||
                  global.NOT_AVAILABLE
              )
              .join('\n')
          : warnings
              .map(
                w =>
                  energyProWarningToLabelMapping[w.warning] ||
                  global.NOT_AVAILABLE
              )
              .join('\n')
      }
      extraClassNames={[styles.tooltip]}
      childrenWrapperClassName={styles.tooltip}
      tooltipTextClassName={styles.tooltipText}
      arrowDirection="right"
      disableAutoAdjust
      underline={false}
    >
      <div style={{ top: '2px', position: 'relative' }}>
        {hasError ? (
          <ErrorIcon width="16" height="16" />
        ) : (
          <WarningIcon width="16" height="16" />
        )}
      </div>
    </Tooltip>
  );
};

const EnergyProTile: React.FC<Props> = ({
  canUpdateFirmware,
  siteId,
  data,
  detailLevel,
  onFirmwareVersionLinkClick,
  jumpToRef,
  isEnergyPro2,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const distributionPanelId = (data.distribution_panel_id as InspectionDataField)
    ?.field;
  const detailsPageLink = `${
    Routes.DISTRIBUTION_PANELS
  }/${distributionPanelId}${Routes.ENERGY_PRO_SETTINGS}/${getId(data)}`;
  const mainsPageLink = `${Routes.DISTRIBUTION_PANELS}/${distributionPanelId}${
    Routes.ENERGY_PRO_MAINS
  }/${getId(data)}`;
  const liveReadingsLink = `${
    Routes.DISTRIBUTION_PANELS
  }/${distributionPanelId}${Routes.ENERGY_PRO_LIVE_READINGS}/${getId(data)}`;
  const energyDevicePortsPageLinkPrefix = `${Routes.DISTRIBUTION_PANELS}/${distributionPanelId}${Routes.ENERGY_DEVICE_PORTS}/`;
  const firmwareVersion = (data.firmware_version as InspectionDataField)
    ?.field as string;
  const showSensorBusInfoTooltip =
    !isEnergyPro2 && !isVersionRequirementFulfilled(firmwareVersion, '1.2.235');
  const sensorBusInfoTooltip = (
    <InfoIconTooltip content="Sensor Bus information only available from FW v.1.2.235 onwards" />
  );

  const indexColumn = isEnergyPro2
    ? {
        header: 'Index',
        width: '10%',
        cellContent: ({ index, energy_device_id }) => (
          <div className={tileStyles.tableFieldValue}>
            <Link
              to={`${energyDevicePortsPageLinkPrefix}${
                energy_device_id?.field
              }/${index?.field?.split('Port ')[1] || ''}`}
              target="_blank"
            >
              {index?.field !== undefined ? index.field : global.NOT_AVAILABLE}
            </Link>
            {getErrorOrWarningIconForField(index)}
          </div>
        ),
        skeletonCellContent: skeletonCellContent,
      }
    : {
        header: 'Index',
        width: '11%',
        defaultSortDirection: SortDirection.ASC,
        isDefaultSort: true,
        cellContent: ({ index, energy_device_id }) => (
          <div className={tileStyles.tableFieldValue}>
            <Link
              to={`${energyDevicePortsPageLinkPrefix}${
                energy_device_id?.field
              }/${index?.field?.split('Port ')[1] || ''}`}
              target="_blank"
            >
              {index?.field !== undefined ? index.field : global.NOT_AVAILABLE}
            </Link>
            {getErrorOrWarningIconForField(index)}
          </div>
        ),
        skeletonCellContent: skeletonCellContent,
        comparator: (
          a: InspectionDataFieldsByKey,
          b: InspectionDataFieldsByKey,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'index',
            'field',
          ]);
        },
      };

  const mainsPhaseWarnings = Object.values(EnergyProWarning).filter(w =>
    w.toString().includes('MAINS')
  );

  const columns: any[] = [
    (isEnergyPro2 && {
      header: ' ',
      width: '4%',
      cellContent: ({ sensor_status, sensor_warnings }) => {
        const filteredWarnings =
          sensor_warnings?.filter(
            w => !mainsPhaseWarnings.includes(w.warning)
          ) || [];
        return (
          <div>
            <DeviceConnectionStatus
              connectionStatus={
                sensor_status.field === 'Active' ? true : 'INACTIVE'
              }
              hideText
            />
            {renderWarningOrErrorTooltip(filteredWarnings)}
          </div>
        );
      },
    }) ||
      undefined,
    indexColumn,
    {
      header: 'Breaker',
      width: isEnergyPro2 ? '10%' : '11%',
      cellContent: ({ breaker }) =>
        renderTableCellContent(
          breaker,
          (field: InspectionDataField) =>
            field?.field ? String(field.field) : global.NOT_AVAILABLE,
          siteId,
          undefined,
          undefined,
          getErrorOrWarningIconForField(breaker)
        ),
      skeletonCellContent: skeletonCellContent,
    },
    {
      header: 'Equipment',
      width: isEnergyPro2 ? '12%' : '13%',
      cellContent: ({ equipment_id, equipment_title }) =>
        renderTableCellContent(
          equipment_title,
          (field: InspectionDataField) =>
            field?.field ? String(field.field) : ' ',
          siteId,
          undefined,
          undefined,
          getErrorOrWarningIconForField(equipment_title)
        ),
      skeletonCellContent: skeletonCellContent,
    },
    {
      header: 'Phase',
      width: '8%',
      cellContent: ({ phase, sensor_bus, sensor_warnings }) => {
        if (!phase) {
          return global.NOT_AVAILABLE;
        }
        const isMainBreaker = sensor_bus?.field === 0;
        const { config_phase, erms_phase, proposed_phase } = phase;
        const { phaseLabel, phaseSource } = determinePhaseDisplayAndSource(
          config_phase,
          erms_phase || proposed_phase
        );
        const mainBreakerWarnings =
          sensor_warnings?.filter(w =>
            mainsPhaseWarnings.includes(w.warning)
          ) || [];
        return (
          <div className={tileStyles.tableFieldValue}>
            {phase && isEnergyPro2 && !isMainBreaker ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '50%' }}>{phaseLabel}</div>
                <div>{phaseSource}</div>
              </div>
            ) : (
              PHASE_LABEL[config_phase] || global.NOT_AVAILABLE
            )}
            {phase &&
              isEnergyPro2 &&
              isMainBreaker &&
              renderWarningOrErrorTooltip(mainBreakerWarnings)}
          </div>
        );
      },
      skeletonCellContent: skeletonCellContent,
    },
    {
      header: 'Active Power (kW)',
      width: isEnergyPro2 ? '4%' : '5%',
      cellContent: ({ active_power, reading_timestamp }) =>
        renderTableCellContent(
          active_power,
          (field: InspectionDataField) => formatTableNumber(field?.field),
          siteId,
          liveReadingsLink,
          reading_timestamp,
          getErrorOrWarningIconForField(active_power)
        ),
      skeletonCellContent: skeletonCellContent,
    },
    {
      header: 'Current (A)',
      width: '5%',
      cellContent: ({ current, reading_timestamp }) =>
        renderTableCellContent(
          current,
          (field: InspectionDataField) => formatTableNumber(field?.field),
          siteId,
          liveReadingsLink,
          reading_timestamp,
          getErrorOrWarningIconForField(current)
        ),
      skeletonCellContent: skeletonCellContent,
    },
    {
      header: 'Power Factor',
      width: '5%',
      cellContent: ({ power_factor, reading_timestamp }) =>
        renderTableCellContent(
          power_factor,
          (field: InspectionDataField) => formatTableNumber(field?.field),
          siteId,
          liveReadingsLink,
          reading_timestamp,
          getErrorOrWarningIconForField(power_factor)
        ),
      skeletonCellContent: skeletonCellContent,
    },
  ].filter(c => c !== undefined);

  const fieldsEPro1 = [
    {
      name: 'IP Address',
      key: 'ip',
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
      link: detailsPageLink,
    },
    {
      name: 'DHCP Status',
      key: 'ip_setting',
      transformValue: transformDhcpStatusValue,
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
      onClick: canUpdateFirmware
        ? () =>
            onFirmwareVersionLinkClick?.(
              {
                id: ((data.id as InspectionDataField)?.field as number) || -1,
                vendor:
                  ((data.vendor as InspectionDataField)?.field as Vendor) || '',
              },
              FirmwareGatewayModel.ENERGYPRO
            )
        : undefined,
    },
    {
      name: 'Sensor Bus 1',
      key: 'bus_device_info_1',
      link: mainsPageLink,
      transformValue: transformBusDeviceInfoValue,
      description: showSensorBusInfoTooltip ? sensorBusInfoTooltip : undefined,
    },
    {
      name: 'Power Metering Type',
      key: 'power_metering_type',
      link: detailsPageLink,
      transformValue: value =>
        PowerMeteringType[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: 'Sensor Bus 2',
      key: 'bus_device_info_2',
      link: mainsPageLink,
      transformValue: transformBusDeviceInfoValue,
      description: showSensorBusInfoTooltip ? sensorBusInfoTooltip : undefined,
    },
    {
      name: 'MQTT Broker',
      key: 'mqtt_broker',
      description: !isVersionRequirementFulfilled(
        firmwareVersion,
        '1.5.234'
      ) ? (
        <InfoIconTooltip content="MQTT broker information only available from FW v1.5.234 onwards" />
      ) : (
        undefined
      ),
    },
    {
      name: 'Interval',
      key: 'interval',
      link: detailsPageLink,
      transformValue: value => `${value} sec`,
    },
  ];

  const fieldsEPro2 = [
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
      link: detailsPageLink,
    },
    {
      name: 'Power Metering Type',
      key: 'power_metering_type',
      link: detailsPageLink,
      transformValue: value =>
        PowerMeteringType[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: 'Signal Strength',
      key: 'signal_strength',
      transformValue: value => (value ? `${value} dBm` : global.NOT_AVAILABLE),
    },
    {
      name: 'Interval',
      key: 'interval',
      link: detailsPageLink,
      transformValue: value => `${value} sec`,
    },
    {
      name: 'Sensor Bus 1',
      key: 'bus_device_info_1',
      link: mainsPageLink,
      transformValue: transformBusDeviceInfoValue,
      description: showSensorBusInfoTooltip ? sensorBusInfoTooltip : undefined,
    },
    {
      name: 'Active Ports',
      key: 'num_active_ports',
    },
    {
      name: 'Sensor Bus 2',
      key: 'bus_device_info_2',
      link: mainsPageLink,
      transformValue: transformBusDeviceInfoValue,
      description: showSensorBusInfoTooltip ? sensorBusInfoTooltip : undefined,
    },
  ];

  const subtitle = getTitle(data);
  const allTabsTableData = (data.ct_sensors as InspectionDataFieldsByKey[]).filter(
    ctSensor =>
      detailLevel === InspectionDetailLevel.ALL ||
      doesItemContainErrorOrWarning(ctSensor)
  );
  const tableDataBySensorBus = {};
  allTabsTableData.forEach(ctSensor => {
    const sensorBusField = ctSensor.sensor_bus as InspectionDataField;
    const sensorBus = (sensorBusField?.field === 1 ||
    sensorBusField?.field === 2
      ? sensorBusField.field
      : 0) as number;
    if (!tableDataBySensorBus[sensorBus]) {
      tableDataBySensorBus[sensorBus] = [];
    }

    if (
      isEnergyPro2 &&
      (ctSensor.sensor_status as InspectionDataField).field !== 'Active' &&
      !isDefined((ctSensor.equipment_id as InspectionDataField).field)
    )
      return;

    tableDataBySensorBus[sensorBus].push(ctSensor);
  });

  if (isEnergyPro2) {
    Object.keys(tableDataBySensorBus).forEach(key => {
      tableDataBySensorBus[key].sort((a, b) => {
        return getPortNumberFromIndex(a) - getPortNumberFromIndex(b);
      });
    });
  }

  const shouldHideTable =
    detailLevel === InspectionDetailLevel.ISSUES && !allTabsTableData.length;
  const selectedTableTabData = tableDataBySensorBus[selectedTab];
  useEffect(() => {
    // select a tab with most table entries by default
    const counts = [0, 1, 2].map(tab => tableDataBySensorBus[tab]?.length || 0);
    const tabToSelect = counts.indexOf(Math.max(...counts));
    if (selectedTab !== tabToSelect) {
      setSelectedTab(tabToSelect);
    }
  }, [Object.keys(tableDataBySensorBus).length]);
  const tabIcons = [0, 1, 2].map(tab => {
    let hasError = false;
    let hasWarning = false;
    tableDataBySensorBus[tab]?.some(item => {
      const errorsAndWarnings = checkFieldsErrorsAndWarnings(item);
      const sensorErrorsAndWarnings = checkSensorErrorsAndWarnings(
        item.sensor_warnings || []
      );
      if (errorsAndWarnings.hasError || sensorErrorsAndWarnings.hasError) {
        hasError = true;
        return true;
      } else if (
        errorsAndWarnings.hasWarning ||
        sensorErrorsAndWarnings.hasWarning
      ) {
        hasWarning = true;
        return true;
      }
      return false;
    });
    if (hasError) {
      return <ErrorIcon width="16" height="16" className={styles.tabIcon} />;
    }
    if (hasWarning) {
      return <WarningIcon width="16" height="16" className={styles.tabIcon} />;
    }
    return null;
  });
  const summaryFields = getSummaryFields(data, detailsPageLink);
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(
    data.ct_sensors as InspectionDataFieldsByKey[],
    {
      statisticsData: {
        result: 'sensors',
        errors:
          ((data.errors_sensors as number) || 0) +
          ((data.errors_whitelist as number) || 0),
        warnings:
          ((data.warnings_sensors as number) || 0) +
          ((data.warnings_whitelist as number) || 0),
      },
    }
  );
  const onlineStatus = summaryFields.onlineStatus;
  if (onlineStatus === 'Offline') {
    sensorsSummaryFields.status = global.NOT_AVAILABLE as any;
  }
  if (
    detailLevel === InspectionDetailLevel.ISSUES &&
    summaryFields.status === InspectionStatus.GOOD &&
    (!sensorsSummaryFields.status ||
      sensorsSummaryFields.status === InspectionStatus.GOOD)
  ) {
    return null;
  }

  const title = isEnergyPro2
    ? InspectionComponentName.ENERGY_PRO2
    : InspectionComponentName.ENERGY_PRO;

  const titleIcon = isEnergyPro2 ? (
    <EnergyPro2 variant="medium" size="28" />
  ) : (
    <EnergyPro variant="medium" size="20" />
  );

  const fields = isEnergyPro2 ? fieldsEPro2 : fieldsEPro1;

  return (
    <InspectionTile
      title={title}
      titleIcon={titleIcon}
      subtitle={
        !!subtitle && (
          <Link to={detailsPageLink} target="_blank">
            {subtitle}
          </Link>
        )
      }
      summaryFields={summaryFields}
      detailFields={getDetailFields(fields, data, detailLevel)}
      detailTableHeader={
        shouldHideTable ? (
          undefined
        ) : (
          <>
            <InspectionHeader
              title={title + ' CT Sensors'}
              summaryFields={sensorsSummaryFields}
            />
            <Cell width="13" className={styles.tabsContainer}>
              <Tabs>
                <Tab
                  active={selectedTab === 0}
                  onClick={() => setSelectedTab(0)}
                >
                  <div className={styles.tab}>
                    <div>{title}</div>
                    {tabIcons[0]}
                  </div>
                </Tab>
                <Tab
                  active={selectedTab === 1}
                  onClick={() => setSelectedTab(1)}
                >
                  <div className={styles.tab}>
                    <div>Bus 1</div>
                    {tabIcons[1]}
                  </div>
                </Tab>
                <Tab
                  active={selectedTab === 2}
                  onClick={() => setSelectedTab(2)}
                >
                  <div className={styles.tab}>
                    <div>Bus 2</div>
                    {tabIcons[2]}
                  </div>
                </Tab>
              </Tabs>
            </Cell>
          </>
        )
      }
      showDetailTableHeaderWhenCollapsed
      detailTable={
        shouldHideTable
          ? undefined
          : {
              dataIsLoading: false,
              highlightAlternateRows: true,
              columns,
              data: selectedTableTabData || [],
            }
      }
      noTableDataMessage="No Sensors Configured"
      jumpToRef={jumpToRef}
    />
  );
};

export default EnergyProTile;
