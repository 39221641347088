import { Button, FileIcon } from '@energybox/react-ui-library/dist/components';
import { Trash } from '@energybox/react-ui-library/dist/icons';
import { Box, IconButton } from '@material-ui/core';
import { ReactElement } from 'react';
import {
  isUploading,
  UploadFile,
  UploadFileUploaded,
  UploadFileUploading,
  UploadResponse,
  UploadStatus,
} from '../../types/upload';
import styles from './FileUploadCell.module.css';
import FileUploader from './FileUploader';
import {
  classNames,
  displayByte,
  getFileTypeByName,
} from '@energybox/react-ui-library/dist/utils';

interface UploadCellProps<T extends object> {
  upload: UploadFile<T>;
  siteId?: number;
  uploadFile: (
    upload: UploadFileUploading<T>,
    siteId?: number | undefined
  ) => Promise<UploadResponse>;
  status: UploadStatus;
  uploadDidFinish: (uploaded: UploadFileUploaded<T>) => void;
  retry: () => void;
  viewFile: (fileId: string, fileName: string) => Promise<void>;
  uploadResult?: string | null;
  remove: () => void;
  isHorizontal?: boolean;
  streamDataPath?: string[];
  isUndeletable?: boolean;
  isUploadFromEdmPage?: boolean;
}

const StatusLabel = ({ status }: { status: UploadStatus }) => {
  switch (status) {
    case UploadStatus.pending:
      return <span className={styles.pending}>Upload Pending</span>;
    case UploadStatus.ready:
      return <span className={styles.pending}>Ready to Upload</span>;
    case UploadStatus.failed:
      return <span className={styles.failed}>Upload Failed</span>;
    case UploadStatus.done:
      return <span className={styles.done}>Upload Done</span>;
    default:
      return <></>;
  }
};

// Cell Container
// Only handle UI update, do not deal with upload logic

// All upload status are contained inside this component

// A string uploadResult (ie a completed result) can be set by two ways:
// 1. Initially set from uploadFromTable props which is an already completed upload
// 2. Trigger by uploadDidFinish within Uploader, which is a newly completed upload

// uploadFromTable are only updated when all uploads finish
// and the whole UtilityBillCard is re-rendered
const FileUploadCell = <T extends object>({
  upload,
  uploadFile,
  status,
  siteId,
  uploadDidFinish,
  retry,
  viewFile,
  uploadResult,
  remove,
  isHorizontal,
  streamDataPath,
  isUndeletable,
  isUploadFromEdmPage,
}: UploadCellProps<T>): ReactElement => {
  const { pending, ready, uploading, failed, done } = UploadStatus;
  const { name, size, type } = upload.file;
  const applyGridStyles = () => {
    switch (true) {
      case isHorizontal && isUploadFromEdmPage:
        return styles.horizontalFromEdm;
      case isHorizontal && !isUploadFromEdmPage:
        return styles.horizontal;
      case !isHorizontal && isUploadFromEdmPage:
        return styles.verticalFromEdm;
      default:
        return styles.vertical;
    }
  };
  return (
    <Box
      className={classNames(styles.root, styles.gridDisplay, applyGridStyles())}
    >
      <Box className={styles.fileIcon}>
        <FileIcon
          fileType={getFileTypeByName(name)}
          size={32}
          inactive={[pending, failed].includes(status)}
        />
      </Box>
      <Box className={styles.fileDetail}>
        <Box className={styles.fileName}>{upload.file.name}</Box>
        <Box className={styles.fileSize}>
          {displayByte(size)}
          {/* {status === done && (
            <Button
              variant="text"
              size="small"
              className={styles.textButton}
              onClick={() => (uploadResult ? viewFile(uploadResult) : {})}
            >
              View File
            </Button>
          )} */}
          {status === failed && (
            <Button
              variant="text"
              size="small"
              className={styles.textButton}
              onClick={retry}
            >
              Retry Upload
            </Button>
          )}
        </Box>
      </Box>
      {(!isHorizontal || !isUploading(upload)) && (
        <Box textAlign="right" className={styles.status}>
          <StatusLabel status={status} />
        </Box>
      )}
      {status !== UploadStatus.done && (
        <Box className={styles.deleteButton}>
          <IconButton
            className={styles.deleteIconButton}
            onClick={remove}
            disabled={isUndeletable}
          >
            <Trash />
          </IconButton>
        </Box>
      )}
      <Box className={isHorizontal ? styles.status : styles.progressBar}>
        {isUploading(upload) && uploadResult == null && (
          <FileUploader
            upload={upload}
            siteId={siteId}
            uploadDidFinish={uploadDidFinish}
            uploadFile={uploadFile}
            streamDataPath={streamDataPath}
          />
        )}
      </Box>
    </Box>
  );
};

export default FileUploadCell;
