import {
  Space,
  User,
  UserGroup,
  Site,
  UserRole,
  reminderTypesList,
  Sop,
  EquipmentType,
  Equipment,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  InputField,
  Label,
  Tabs,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEquipments,
  updatedEquipmentList,
  upDatedSentiel,
} from '../../actions/equipment';
import { fetchEquipment } from '../../actions/equipmentFetch';
import { fetchGateways } from '../../actions/gatewaysFetch';
import { openModal } from '../../actions/modal';
import { fetchSensors } from '../../actions/sensorsFetch';
import { fetchSpaces } from '../../actions/spacesFetch';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import { SitesById } from '../../reducers/sites';
import { SpacesById } from '../../reducers/spaces';
import { Routes } from '../../routes';
import { reminderSteps, StepType } from '../../types/reminderWizard';
import ModalFormContent from '../ModalFormContent';
import TabLink from '../TabLink';
import styles from './ReminderWizard.module.css';
import ReminderWizardNavigation from './ReminderWizardNavigation';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useCurrentUser } from '../../hooks/useAppDetails';
import * as R from 'ramda';
import StepReminderType from './steps/StepReminderType';
import {
  ReminderType,
  Reminder,
} from '@energybox/react-ui-library/dist/types/Reminder';
import StepRolloutContainer from './steps/StepRollout/StepRolloutContainer';
import { getSops } from '../../actions/sops';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import StepHandling, { Recipient } from './steps/StepHandling/StepHandling';
import {
  createReminder,
  getReminderById,
  updateReminder,
} from '../../actions/reminder';
const stepsKeys = Object.keys(reminderSteps);

type Props = {
  id?: string;
  reminder?: Reminder;
  step: StepType;
  userRole: UserRole;
  sites: Site[];
  users: User[];
  userGroups: UserGroup[];
  openModal: typeof openModal;
  fetchEquipment: typeof fetchEquipment;
  fetchSpaces: typeof fetchSpaces;
  fetchGateways: typeof fetchGateways;
  fetchSensors: typeof fetchSensors;
};

export type TargetedSopMapping = {
  sop: Sop;
  recipients: Recipient[];
};

export type TargetedEntitiesMappingBySiteId = {
  [siteId: number]: TargetedSopMapping[];
};

export interface Errors {
  title: {
    onSave?: string;
  };
  rollout: {
    onSave?: string;
    site?: string;
  };
  handling: {
    sop?: number[];
    onSave?: string;
  };
}

const initErrors: Errors = {
  title: {},
  rollout: {},
  handling: {},
};

const TASK_REMINDER_TYPE = 'TASK';

function ReminderWizard(props: Props) {
  const currentUser = useCurrentUser();
  const orgId = useCurrentOrganizationId();
  const { id = 'new', step, users, userGroups, sites = [], openModal } = props;

  const dispatch = useDispatch();
  // const stepHandlingRef = useRef<{ handleItemValidation: () => void }>();
  const newListEquipment = useSelector(
    ({ equipment }: ApplicationState) => equipment.getUpdatedEquipmentList
  );
  const reminderFromPath = useSelector(
    ({ reminder }: ApplicationState) =>
      reminder.reminders.filter(i => i.id === Number(id))[0]
  );
  const equipmentsFromAppState = useSelector(
    ({ equipment }: ApplicationState) =>
      R.uniqBy(item => item.id, equipment.equipmentPayloadArray)
  );

  const taskSops = useSelector(({ sops }: ApplicationState) =>
    Object.values(sops.sopsById).filter(sop =>
      sop.components.some(c => c.type === TASK_REMINDER_TYPE)
    )
  );

  const isSopsLoading = useSelector(
    ({ sops }: ApplicationState) => sops.isLoading
  );

  let setTitleValue: string = reminderFromPath ? reminderFromPath.title : '';

  const [title, setTitle] = useState(setTitleValue);
  const [description, setDescription] = useState(
    (reminderFromPath && reminderFromPath.description) || ''
  );
  const [reminderType, setReminderType] = useState(ReminderType.DAILY_CHECK);
  const [nextToRollout, setNextToRollout] = useState(
    reminderType !== ReminderType.NONE
  );
  const [nextToHandling, setNextToHandling] = useState(false);
  const [errors, setErrors] = useState<Errors>(initErrors);
  const [isErrorField, isSetErrorField] = useState(false);
  const [isEmailErrorField, isSetEmailErrorField] = useState(false);
  const [action, setAction] = useState('');
  const [isFormSaveError, isSetFormSaveError] = useState(false);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentType[]>([]);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<
    EquipmentType[]
  >([]);
  const [isSelectedSiteError, setIsSelectedSiteError] = useState(false);
  const [isSelectedSopError, setIsSelectedSopError] = useState(false);
  const [editBtnEnable, setEditBtnEnable] = useState<boolean[]>([false]);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [targetedSites, setTargetedSites] = useState<Site[]>([]);
  const [targetedSitesLoading] = useState<boolean>(false);
  const [targetedEntitiesBySiteId, setTargetedEntitiesBySiteId] = useState<
    TargetedEntitiesMappingBySiteId
  >({});

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getReminderById(+id));
    }
  }, [id]);

  useEffect(() => {
    let getEquipmentsParam = {};
    dispatch(getEquipments(getEquipmentsParam));
    let getSopsParams = {
      types: [TASK_REMINDER_TYPE],
    };
    dispatch(getSops(getSopsParams));
  }, [targetedSites]);

  useEffect(() => {
    if (!R.equals(equipmentsFromAppState, equipments)) {
      setEquipments(equipmentsFromAppState);
    }
  }, [equipmentsFromAppState]);

  useEffect(() => {
    const filteredEquipmentTypes = equipments
      .filter(equipment => {
        return (
          taskSops.find(sop =>
            sop.equipmentTypeIds?.includes(equipment.typeId)
          ) && equipment.type !== undefined
        );
      })
      .map(i => i.type) as EquipmentType[];
    const uniqFilteredEquipmentTypes = R.uniqBy(
      R.path(['id']),
      filteredEquipmentTypes
    );
    const hasEquipmentTypesChanged = !R.equals(
      equipmentTypes,
      uniqFilteredEquipmentTypes
    );
    if (hasEquipmentTypesChanged) {
      setEquipmentTypes(uniqFilteredEquipmentTypes);
    }
  }, [equipments, taskSops]);

  useEffect(() => {
    let entitiesMappingBySiteId: TargetedEntitiesMappingBySiteId = {};
    const isTargetedSitesEmpty = targetedSites?.length === 0;
    if (!isTargetedSitesEmpty) {
      let errors: Errors = initErrors;
      setErrors({ ...errors });
    }
    targetedSites.forEach(site => {
      if (isDefined(targetedEntitiesBySiteId[site.id])) {
        entitiesMappingBySiteId[site.id] = R.clone(
          targetedEntitiesBySiteId[site.id]
        );
      } else {
        entitiesMappingBySiteId[site.id] = [];
      }
    });
    setTargetedEntitiesBySiteId(entitiesMappingBySiteId);
  }, [targetedSites]);

  useEffect(() => {
    const selectedSops = Object.values(targetedEntitiesBySiteId)
      .map(i => i.map(e => e.sop).flat())
      .flat();
    if (selectedSops.length > 0) {
      let errors: Errors = initErrors;
      setErrors({ ...errors });
      setNextToHandling(true);
    } else {
      setNextToHandling(false);
    }
    isSetEmailErrorField(false);
  }, [targetedEntitiesBySiteId]);

  useEffect(() => {
    if (title !== '') {
      let tempErrors: Errors = initErrors;
      setErrors({ ...errors, title: tempErrors.title });
      isSetFormSaveError(false);
    }
  }, [title]);

  useEffect(() => {
    if (
      id !== 'new' &&
      reminderFromPath &&
      !isEdited &&
      !isSopsLoading &&
      taskSops.length > 0 &&
      users.length > 0 &&
      sites.length > 0
    ) {
      if (!R.equals(title, reminderFromPath?.title)) {
        setTitle(reminderFromPath?.title);
        setIsEdited(true);
      }
      if (!R.equals(description, reminderFromPath?.description)) {
        setDescription(reminderFromPath?.description);
        setIsEdited(true);
      }
      const siteIds = R.uniq(reminderFromPath?.components?.map(i => i.siteId));
      const newTargetedSites = sites.filter(i => siteIds?.includes(i.id));
      if (!R.equals(newTargetedSites, targetedSites)) {
        setTargetedSites(newTargetedSites);
        setIsEdited(true);
      }

      const newTarget: TargetedEntitiesMappingBySiteId = {};
      siteIds?.forEach(i => {
        newTarget[i] = [];
        const foundComponents = reminderFromPath?.components?.filter(
          a => a.siteId === i
        );
        foundComponents?.forEach(e => {
          const recipientIds = e.recipients?.map(a => a.id);
          const foundSop = newTarget[i].find(a => a.sop?.id === e.sopId);
          const foundSopIndex = newTarget[i].findIndex(
            a => a.sop?.id === e.sopId
          );
          const foundUsers = users
            .filter(y => recipientIds?.includes(y.id))
            .map(z => {
              return {
                id: z.id,
                identifier: String(z.id),
                title: z.email,
              };
            });
          if (foundSop === undefined) {
            newTarget[i].push({
              sop: taskSops?.filter(x => x.id === e.sopId)[0],
              recipients: foundUsers,
            });
          } else {
            foundSop.recipients.push(...foundUsers);
            newTarget[i][foundSopIndex] = foundSop;
          }
        });
      });
      if (!R.equals(newTarget, targetedEntitiesBySiteId)) {
        setTargetedEntitiesBySiteId(newTarget);
        setNextToRollout(true);
        setNextToHandling(true);
        setIsEdited(true);
      }
    }
  }, [users, reminderFromPath, taskSops, sites]);

  function validateStepType() {
    return reminderType !== ReminderType.NONE;
  }

  function validateStepRollout() {
    if (!validateStepType()) {
      return false;
    }
    if (targetedSites.length === 0) {
      return false;
    }
    return true;
  }

  function validateStepHandling() {
    if (!(validateStepType() && validateStepRollout())) {
      return false;
    }

    return true;
  }

  const stepsCompleted = {
    [StepType.type]: validateStepType(),
    [StepType.rollout]: validateStepRollout(),
    [StepType.handling]: validateStepHandling(),
  };

  function composeApiRequestComponents() {
    const siteIds = Object.keys(targetedEntitiesBySiteId);
    const components = siteIds
      .map(i =>
        targetedEntitiesBySiteId[i].map(e => {
          return {
            siteId: Number(i),
            sopId: e.sop.id,
            recipients: e.recipients.map(x => {
              const user = users.find(u => u.id === x.id);
              return {
                id: x.id,
                email: user?.email,
              };
            }),
          };
        })
      )
      .flat();
    return components;
  }

  function create(isFinishLater = false) {
    if (handleCreateValidation(isFinishLater)) {
      const components = composeApiRequestComponents();
      dispatch(
        createReminder({
          title,
          description,
          type: 'DAILY_CHECK',
          components: components,
          completed: !isFinishLater,
        })
      );
      history.push({
        pathname: `${Routes.REMINDER}`,
      });
    }
  }

  function update(isFinishLater = false) {
    if (handleCreateValidation(isFinishLater)) {
      const components = composeApiRequestComponents();
      dispatch(
        updateReminder({
          id: +id,
          title,
          description,
          type: 'DAILY_CHECK',
          components: components,
          completed: !isFinishLater,
        })
      );
      history.push({
        pathname: `${Routes.REMINDER}`,
      });
    }
  }

  function handleFinishLaterConfirm() {
    if (id !== 'new' && reminderFromPath) {
      update(true);
    } else {
      create(true);
    }
  }

  function findOverrideSop(sop, taskSops, orgId) {
    return taskSops.find(item => {
      return (
        item.organizationUnitId !== orgId &&
        // sop.equipmentTypeIds === item.equipmentTypeIds
        sop.equipmentTypeIds.reduce((match, equipTypeId) => {
          return item.equipmentTypeIds.includes(equipTypeId) || match;
        }, false)
      );
    });
  }

  function handleOnSave() {
    if (handleCreateValidation()) {
      openModal({
        title: 'Save Changes',
        description: `Are you sure you would like to save the changes on this configuration?`,
        actionFn: update,
      });
    }
  }

  function handleCancelNew() {
    openModal({
      title: 'Cancel Reminder Setup',
      description: `Are you sure you would like to cancel this new configuration?`,
      actionFn: () => history.push('/reminder'),
    });
  }

  function handleDiscard() {
    openModal({
      title: 'Discard Reminder Changes',
      description: `Are you sure you would like to discard any changes to this configuration?`,
      actionFn: () => history.push('/reminder'),
    });
  }

  function handleFinishLater() {
    openModal({
      title: 'Create Unfinished Reminder',
      description: `Are you sure you would like to create an unfinished reminder (Notifications will not be activated)`,
      actionFn: handleFinishLaterConfirm,
    });
  }

  function onTypeChange(reminderType: ReminderType) {
    switch (reminderType) {
      case ReminderType.DAILY_CHECK:
        break;

      default:
        throw new Error('Unknown reminder type');
    }

    setReminderType(reminderType);
  }

  function onAddHandling(siteId, sopId, recipients) {
    const updatedTarget = targetedEntitiesBySiteId;
    const foundIndex = targetedEntitiesBySiteId[siteId]?.findIndex(
      i => i.sop?.id === sopId || false
    );
    const foundItem = targetedEntitiesBySiteId[siteId]?.find(
      i => i.sop?.id === sopId
    );
    if (typeof foundIndex === 'number' && foundItem) {
      updatedTarget[siteId][foundIndex] = {
        sop: foundItem.sop,
        recipients,
      };
    }
    setIsEdited(true);
    setTargetedEntitiesBySiteId(R.clone(updatedTarget));
    isSetEmailErrorField(false);
  }

  function onRemoveHandling(siteId, sopId, recipients) {
    const updatedTarget: TargetedEntitiesMappingBySiteId = {};
    for (const [siteId, value] of Object.entries(targetedEntitiesBySiteId)) {
      updatedTarget[siteId] = value;
    }
    const foundIndex = targetedEntitiesBySiteId[siteId]?.findIndex(
      i => i.sop?.id === sopId
    );
    const foundItem = targetedEntitiesBySiteId[siteId]?.find(
      i => i.sop?.id === sopId
    );
    if (typeof foundIndex === 'number' && foundItem) {
      updatedTarget[siteId][foundIndex] = {
        sop: foundItem.sop,
        recipients,
      };
    }
    setIsEdited(true);
    setTargetedEntitiesBySiteId(updatedTarget);
    isSetEmailErrorField(false);
  }

  function onUpdateHandling(index: number) {
    return () => {};
  }

  function onAddRemoveTarget(siteId: string | number, sopId: string | number) {
    const hasTarget =
      targetedEntitiesBySiteId[siteId]?.find(item => item?.sop?.id === sopId) ||
      undefined;
    const updatedTarget = targetedEntitiesBySiteId;
    if (hasTarget) {
      updatedTarget[siteId] = targetedEntitiesBySiteId[siteId]?.filter(
        item => item.sop?.id !== sopId
      );
    } else {
      updatedTarget[siteId] = [
        ...(targetedEntitiesBySiteId[siteId] || undefined),
        { sop: taskSops.find(item => item.id === sopId), recipients: [] },
      ].filter(i => i !== undefined);
    }
    setIsEdited(true);
    setTargetedEntitiesBySiteId(R.clone(updatedTarget));
    setIsSelectedSiteError(false);
    setIsSelectedSopError(false);
    isSetEmailErrorField(false);
  }

  function onAddTargets(sops: Sop[], sites: Site[]) {
    const updatedTarget = targetedEntitiesBySiteId;
    sops.forEach(sop => {
      sites.forEach(site => {
        const sopsBySiteByEquipment = sops.filter(item => {
          return (
            item.organizationUnitId === site.id ||
            item.organizationUnitId === orgId
          );
        });
        const hasOverride = findOverrideSop(sop, sopsBySiteByEquipment, orgId);
        const isSiteLevelSop = sop.organizationUnitId !== orgId;
        const equipmentTypeIdsBySite = equipments
          .filter(i => {
            if (i.space) {
              return i.space?.parentId === site.id;
            } else {
              return false;
            }
          })
          .map(i => i.typeId);
        const hasTarget = updatedTarget[site.id]?.find(
          i => i.sop?.id === sop?.id
        );
        if (!hasTarget) {
          if (
            (sop.organizationUnitId === site.id ||
              sop.organizationUnitId === orgId) &&
            sop.equipmentTypeIds.some(e => equipmentTypeIdsBySite.includes(e))
          ) {
            if (!isSiteLevelSop && hasOverride) {
              // do nothing
            } else {
              updatedTarget[site.id]?.push({ sop, recipients: [] });
            }
          }
        }
      });
    });
    setIsEdited(true);
    setTargetedEntitiesBySiteId(R.clone(updatedTarget));
    setIsSelectedSiteError(false);
    setIsSelectedSopError(false);
    isSetEmailErrorField(false);
  }

  function onDeleteTargets(sops: Sop[], sites: Site[]) {
    const updatedTarget = targetedEntitiesBySiteId;
    sops.forEach(sop => {
      sites.forEach(site => {
        const hasTarget = updatedTarget[site.id]?.find(
          i => i.sop?.id === sop.id
        );
        if (hasTarget) {
          if (
            sop.organizationUnitId === site.id ||
            sop.organizationUnitId === orgId
          ) {
            updatedTarget[site.id] = updatedTarget[site.id].filter(
              i => i.sop?.id !== sop?.id
            );
          }
        }
      });
    });
    setIsEdited(true);
    setTargetedEntitiesBySiteId(R.clone(updatedTarget));
    setIsSelectedSiteError(false);
    setIsSelectedSopError(false);
    isSetEmailErrorField(false);
  }

  function handleUpdateSelectedEquipmentTypes(equipmentTypes) {
    setSelectedEquipmentType(equipmentTypes);
  }

  const currentStepIndex = stepsKeys.findIndex(s => s === step.toString());

  const prevStepKeyIndex = currentStepIndex - 1;
  const nextStepKeyIndex = currentStepIndex + 1;

  const prevStep = reminderSteps[stepsKeys[prevStepKeyIndex]];
  let nextStep = reminderSteps[stepsKeys[nextStepKeyIndex]];

  const prevAction = () => {
    if (prevStep) {
      history.push(`/reminder/${id}/${prevStep.key}`);
      isSetFormSaveError(false);
    }
  };

  const nextAction = () => {
    if (nextStep) {
      if (nextStep['title'] === 'Handling') {
        let errors: Errors = initErrors;
        const selectedSops = Object.values(targetedEntitiesBySiteId)
          .map(i => i.map(e => e.sop).flat())
          .flat();
        if (targetedSites.length === 0) {
          errors.rollout.site = `Please select sites and SOP you want to be reminded for`;
          setErrors({ ...errors });
          setIsSelectedSiteError(true);
        } else if (selectedSops.length === 0) {
          errors.rollout.onSave = `Please select the SOP you want to be reminded for`;
          setErrors({ ...errors });
          setIsSelectedSopError(true);
        } else {
          setErrors({ ...errors });
          setNextToHandling(true);
          history.push(`/reminder/${id}/${nextStep.key}`);
        }
      }
      if (nextStep['title'] === 'Rollout') {
        setNextToRollout(true);
        history.push(`/reminder/${id}/${nextStep.key}`);
      }
    } else {
      if (id !== 'new' && reminderFromPath) {
        update(false);
      } else {
        create(false);
      }
    }
  };

  const handleValidation = item => {
    let errors: Errors = initErrors;
    let formIsValid = true;

    let newData = Object.values(action);
    newData.forEach(data => {
      if (data['recipients'].length === 0 || editBtnEnable.includes(false)) {
        formIsValid = false;
        isSetFormSaveError(true);
        errors.handling.onSave =
          'Please save or cancel the changes you made to the action above';
      }
    });
    setErrors({ ...errors });
    return formIsValid;
  };

  const handleCreateValidation = (isFinishLater = false) => {
    let errors: Errors = initErrors;
    let formIsValid = true;
    const allSelectedSites = Object.keys(targetedEntitiesBySiteId);
    if (!isFinishLater) {
      if (targetedSites.length === 0) {
        formIsValid = false;
        setIsSelectedSiteError(true);
        errors.rollout.site = `Please select sites and SOP you want to be reminded for`;
        if (id !== 'new' && reminderFromPath) {
          history.push(`${Routes.REMINDER}/${id}/rollout`);
        }
        setErrors({ ...errors });
      }
      if (formIsValid) {
        allSelectedSites.forEach(siteId => {
          if (targetedEntitiesBySiteId[siteId].length !== 0) {
            targetedEntitiesBySiteId[siteId].forEach(
              (targetSop: TargetedSopMapping) => {
                if (targetSop?.recipients?.length === 0) {
                  formIsValid = false;
                  isSetEmailErrorField(true);
                  errors.handling.onSave = `Please select the person should be reminded if Check is incomplete`;
                  if (id !== 'new' && reminderFromPath) {
                    history.push(`${Routes.REMINDER}/${id}/handling`);
                  }
                }
                if (formIsValid) {
                  isSetEmailErrorField(false);
                }
                setErrors({ ...errors });
              }
            );
          }
        });
      }
    }
    if (formIsValid) {
      if (title === '') {
        formIsValid = false;
        isSetFormSaveError(true);
        errors.title.onSave = 'Please fill out the title of Reminder';
        setErrors({ ...errors });
      }
    }
    return formIsValid;
  };

  if (!currentUser) return null;

  function filterSitesBySop(sites: Site[], sops: Sop[]) {
    return sites.filter(site => {
      const equipmentBySiteId = equipments.filter(
        i => i.space?.parentId === site.id
      );
      const equipmentTypeIds = equipmentBySiteId.map(i => i.typeId);
      return sops.find(
        sop =>
          (sop.organizationUnitId === site.id ||
            sop.organizationUnitId === orgId) &&
          equipmentTypeIds.some(i => sop.equipmentTypeIds.includes(i))
      );
    });
  }

  function updateEquipmentList(list) {
    dispatch(updatedEquipmentList(list));
  }

  function upDatedSentinelId(sentinelId) {
    dispatch(upDatedSentiel(sentinelId));
  }

  return (
    <div>
      <ModalFormContent className={styles.formContent}>
        <Label>Title *</Label>
        <InputField
          type="text"
          onChange={ev => setTitle(ev.currentTarget.value)}
          value={title}
          name="title"
        />
        <span></span>
        <span className={styles.errorContainer}>
          {isFormSaveError ? errors.title.onSave : ''}
        </span>

        <Label>Description</Label>
        <InputField
          type="text"
          onChange={ev => setDescription(ev.currentTarget.value)}
          value={description}
          name="description"
        />
      </ModalFormContent>

      <Tabs className={styles.tabs}>
        {(reminderType === ReminderType.NONE || id === 'new') && (
          <TabLink to={`${Routes.REMINDER}/${id}/type`}>Type</TabLink>
        )}
        <TabLink
          disabled={
            (id === 'new' && !stepsCompleted[StepType.type]) ||
            nextToRollout === false
          }
          to={`${Routes.REMINDER}/${id}/rollout`}
        >
          Rollout
        </TabLink>
        <TabLink
          disabled={
            (id === 'new' && !stepsCompleted[StepType.rollout]) ||
            nextToHandling === false
          }
          to={`${Routes.REMINDER}/${id}/handling`}
        >
          Handling
        </TabLink>
      </Tabs>

      <Card>
        <CardContent>
          {(step === StepType.type || reminderType === ReminderType.NONE) && (
            <StepReminderType
              onChange={onTypeChange}
              reminderType={reminderType}
              reminderTypesList={reminderTypesList}
            />
          )}

          {step === StepType.rollout && (
            <StepRolloutContainer
              orgId={orgId || 0}
              sites={filterSitesBySop(sites, taskSops)}
              sops={taskSops}
              equipments={equipments}
              setTargetedSites={siteList => setTargetedSites(siteList)}
              targetedSites={targetedSites}
              targetedSitesLoading={targetedSitesLoading}
              reminderType={reminderType}
              onTargetChange={onAddRemoveTarget} // Select single target checkbox
              onTargetsChange={onAddTargets} // Select all target checkboxes of a certain target type
              onTargetsDelete={onDeleteTargets}
              targets={targetedEntitiesBySiteId}
              equipmentTypes={equipmentTypes}
              selectedEquipmentType={selectedEquipmentType}
              setSelectedEquipmentType={handleUpdateSelectedEquipmentTypes}
              openModal={openModal}
              errors={errors}
              setErrors={setErrors}
              setIsSelectedSiteError={setIsSelectedSiteError}
              isSelectedSiteError={isSelectedSiteError}
              setIsSelectedSopError={setIsSelectedSopError}
              isSelectedSopError={isSelectedSopError}
              updateEquipmentList={updateEquipmentList}
              newListEquipment={newListEquipment}
              upDatedSentielId={upDatedSentinelId}
            />
          )}

          {step === StepType.handling && (
            <StepHandling
              handleAddStepHandling={onAddHandling}
              handleRemoveStepHandling={onRemoveHandling}
              handleUpdateStepHandling={onUpdateHandling}
              sites={targetedSites}
              selectedSops={Object.values(targetedEntitiesBySiteId)
                .map(i => i.map(e => e.sop).flat())
                .flat()
                .filter(i => i !== undefined)}
              users={users}
              userGroups={userGroups}
              targets={targetedEntitiesBySiteId}
              errors={errors}
              isSetErrorField={isSetErrorField}
              isErrorField={isErrorField}
              isSetEmailErrorField={isSetEmailErrorField}
              isEmailErrorField={isEmailErrorField}
              setAction={setAction}
              isFormSaveError={isFormSaveError}
              isSetFormSaveError={isSetFormSaveError}
              handleValidation={handleValidation}
              setEditBtnEnable={setEditBtnEnable}
              editBtnEnable={editBtnEnable}
            />
          )}
        </CardContent>
        <CardActions>
          {(id === 'new' && step) ||
          (reminderFromPath && !reminderFromPath.completed && step) ? (
            <ReminderWizardNavigation
              setStep={step => history.push(`/reminder/new/${step}`)}
              handleFinishLater={handleFinishLater}
              handleSubmit={() => {}}
              handleCancel={handleCancelNew}
              loading={false}
              stepsCompleted={stepsCompleted}
              currentStep={step}
              prevStep={prevStep}
              nextStep={nextStep}
              prevAction={prevAction}
              nextAction={nextAction}
            />
          ) : (
            <>
              <Button variant="text" onClick={handleDiscard}>
                Cancel
              </Button>
              <Button onClick={handleOnSave}>Save</Button>
            </>
          )}
        </CardActions>
      </Card>
    </div>
  );
}

export default ReminderWizard;
