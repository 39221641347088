import { EnergyboxService } from '../config';
import { FileType } from '../types/files';
import { UploadFileUploading, UploadResponse } from '../types/upload';
import { fetchApi } from '../utils/apiUtils';

export const apiBase = '/api/v2/files';

import Actions from './FileActions';

export const uploadFile = async <T extends object>(params: {
  fileType: FileType;
  upload: UploadFileUploading<T>;
  siteId?: number;
}): Promise<UploadResponse> => {
  const { file, fileId, ...otherFromUpload } = params.upload;
  const { name } = file;

  const payload = new FormData();
  const metadata = JSON.stringify({
    ...otherFromUpload,
    siteId: params.siteId,
  });

  payload.append('file', file);
  payload.append('fileName', name);
  payload.append('resourceId', params.siteId?.toString() ?? '');
  payload.append('fileType', params.fileType);
  payload.append('trackProgress', 'true');
  payload.append('metadata', metadata);

  return fetchApi({
    service: EnergyboxService.files,
    endpoint: `${apiBase}/upload`,
    method: 'POST',
    payload,
    sendingFormData: true,
  });
};

export const fetchDownloadFile = async (fileId: string, fileName: string) => {
  try {
    const url = await fetchApi({
      service: EnergyboxService.files,
      endpoint: `${apiBase}/download/${fileId}`,
      method: 'GET',
    });
    const downloader = document.createElement('a');
    downloader.href = url as string;
    downloader.setAttribute('download', fileName);
    document.body.appendChild(downloader);
    downloader.click();
    document.body.removeChild(downloader);
  } catch (e) {
    return;
  }
};

export const downloadFile = (fileId: string, fileName?: string) => ({
  type: 'API_GET',
  path: `${apiBase}/download/${fileId}`,
  // payload: { fileId, ttl: 900 },
  loading: { type: Actions.DOWNLOAD_FILE_LOADING, fileName },
  success: { type: Actions.DOWNLOAD_FILE_SUCCESS, fileName },
  error: { type: Actions.DOWNLOAD_FILE_ERROR, fileName },
  service: EnergyboxService.files,
});

export const downloadFileDone = () => ({
  type: Actions.DOWNLOAD_FILE_DONE,
  data: {},
});

export const getFiles = (id?: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}?fileType=REPORT`,
  loading: Actions.MY_DOWNLOADS_LOADING,
  success: Actions.MY_DOWNLOADS_SUCCESS,
  error: Actions.MY_DOWNLOADS_ERROR,
  service: EnergyboxService.files,
});

export const getFilesBySiteId = (params: {
  siteId: number | string;
  fileType: FileType;
  page: string;
}) => ({
  service: EnergyboxService.files,
  type: 'API_GET',
  path: `${apiBase}?resourceId=${params.siteId}&fileType=${params.fileType}`,
  success: {
    type: Actions.GET_FILES_BY_SITE_ID_SUCCESS,
    page: params.page,
  },
  error: { type: Actions.GET_FILES_BY_SITE_ID_ERROR, page: params.page },
  loading: { type: Actions.GET_FILES_BY_SITE_ID_LOADING, page: params.page },
});

export const deleteFilesById = (params: {
  fileId: string;
  page: string;
  callback?: (res: any) => void;
}) => ({
  service: EnergyboxService.files,
  type: 'API_DELETE',
  path: `${apiBase}/${params.fileId}`,
  success: {
    type: Actions.DELETE_FILES_BY_ID_SUCCESS,
    page: params.page,
  },
  error: { type: Actions.DELETE_FILES_BY_ID_ERROR, page: params.page },
  loading: { type: Actions.DELETE_FILES_BY_ID_LOADING, page: params.page },
  callback: params.callback,
});

export const deleteFiles = (fileUuid: string) => {
  return {
    type: 'API_DELETE',
    path: `/api/v2/files/${fileUuid}`,
    loading: Actions.DELETE_FILES_LOADING,
    success: Actions.DELETE_FILES_SUCCESS,
    error: Actions.DELETE_FILES_ERROR,
    service: EnergyboxService.files,
  };
};

export const toggleDeleteStatus = (status: boolean) => ({
  type: Actions.TOGGLE_DELETE_STATE,
  payload: status,
});

export const initiateDownload = (status: boolean) => ({
  type: Actions.INITIATE_DOWNLOAD_FILE,
  payload: status,
});

export const fileDownload = async (fileId: string, fileName: string) => await fetchDownloadFile(fileId, fileName);
