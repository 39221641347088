import React from 'react';
import { CardList, Tooltip } from '@energybox/react-ui-library/dist/components';
import {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import Table, {
  TableProps,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  InspectionDataField,
  InspectionSummaryField,
  InspectionDetailField,
  InspectionJumpToRef,
} from '@energybox/react-ui-library/dist/types';
import { WarningIcon, ErrorIcon } from '@energybox/react-ui-library/dist/icons';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import styles from './InspectionTile.module.css';
import { Link } from 'react-router-dom';
import { TooltipArrowDirection } from '@energybox/react-ui-library/dist/types/util';

type Props = {
  title?: React.ReactNode | string;
  titleIcon?: React.ReactNode;
  subtitle?: React.ReactNode | string;
  summaryFields: InspectionSummaryField;
  detailFields?: InspectionDetailField[];
  detailTableTitle?: string;
  detailTableHeader?: React.ReactNode;
  showDetailTableHeaderWhenCollapsed?: boolean;
  detailTable?: TableProps;
  noTableDataMessage?: string;
  jumpToRef?: InspectionJumpToRef;
  detailExtraFields?: InspectionDetailField[];
};

type InspectionHeaderProps = {
  title?: React.ReactNode | string;
  titleIcon?: React.ReactNode;
  subtitle?: React.ReactNode | string;
  summaryFields: InspectionSummaryField;
};

export const getErrorOrWarningIconForField = (
  field: InspectionDataField | InspectionDetailField,
  arrowDirection: TooltipArrowDirection = 'top'
) => {
  if (field?.error || field?.warning) {
    return (
      <Tooltip
        content={field?.error || field?.warning}
        extraClassNames={[styles.tooltip]}
        childrenWrapperClassName={styles.tooltip}
        tooltipTextClassName={styles.tooltipText}
        arrowDirection={arrowDirection}
        disableAutoAdjust
        underline={false}
      >
        {field?.error ? (
          <ErrorIcon width="16" height="16" />
        ) : (
          <WarningIcon width="16" height="16" />
        )}
      </Tooltip>
    );
  }
  return null;
};

const InspectionTile: React.FC<Props> = ({
  title,
  titleIcon,
  subtitle,
  summaryFields,
  detailFields,
  detailTableTitle,
  detailTableHeader,
  detailTable,
  noTableDataMessage,
  jumpToRef,
  showDetailTableHeaderWhenCollapsed = false,
  detailExtraFields,
}) => {
  const getCellData = (data: InspectionDetailField[]) => {
    return (
      <Cell width="13" className={styles.fieldsContainer}>
        {data?.map(field => {
          if (!field) {
            return null;
          }
          if (field.isHr) {
            return (
              <Cell
                width="6"
                className={classNames(styles.hrContainer, styles.fieldValue)}
              >
                <div className={styles.hr} />
              </Cell>
            );
          }
          const name = field.name ? `${field.name}:` : '';
          return (
            <>
              <Cell width="3" className={styles.fieldTitle}>
                {field.link ? (
                  <Link to={field.link} target="_blank">
                    {name}
                  </Link>
                ) : field.onClick ? (
                  <div
                    className={styles.clickableFieldTitle}
                    onClick={field.onClick}
                  >
                    {name}
                  </div>
                ) : (
                  name
                )}
              </Cell>
              <Cell width="3" className={styles.fieldValue}>
                {field.value}
                {getErrorOrWarningIconForField(field)}
                {!!field.description && (
                  <span className={styles.fieldDescription}>
                    {field.description}
                  </span>
                )}
              </Cell>
            </>
          );
        })}
      </Cell>
    );
  };
  const data: CardListRowData[] = [
    {
      key: 'inspectionData',
      startExpanded: true,
      expandIconFloatToTopRight: true,
      content: ({ isExpanded }) => (
        <>
          <InspectionHeader
            title={title}
            titleIcon={titleIcon}
            subtitle={subtitle}
            summaryFields={summaryFields}
          />
          {!isExpanded &&
            showDetailTableHeaderWhenCollapsed &&
            !!detailTableHeader && (
              <>
                <Cell width="13" className={styles.hrContainer}>
                  <div className={styles.hr} />
                </Cell>
                {detailTableHeader}
              </>
            )}
        </>
      ),
      extraContent:
        (detailFields && detailFields.length > 0) ||
        (detailExtraFields && detailExtraFields.length > 0) ||
        detailTableTitle ||
        detailTableHeader ||
        detailTable
          ? [
              <>
                {detailFields && detailFields.length > 0 && (
                  <Cell width="13" className={styles.hrContainer}>
                    <div className={styles.hr} />
                  </Cell>
                )}
                {!!detailFields && getCellData(detailFields)}
                {(!!detailTableTitle ||
                  !!detailTableHeader ||
                  !!detailTable) && (
                  <Cell width="13" className={styles.hrContainer}>
                    <div className={styles.hr} />
                  </Cell>
                )}
                {!!detailTableTitle && (
                  <Cell width="13" className={styles.fieldTitle}>
                    {detailTableTitle}
                  </Cell>
                )}
                {!!detailTableHeader && detailTableHeader}
                {!!detailTable && (
                  <Cell className={styles.detailTable} width="13">
                    {detailTable.data?.length > 0 && <Table {...detailTable} />}
                    {(!detailTable.data || !detailTable.data.length) && (
                      <div className={styles.noTableData}>
                        {noTableDataMessage || 'Nothing Configured'}
                      </div>
                    )}
                  </Cell>
                )}
                {detailExtraFields && detailExtraFields.length > 0 && (
                  <Cell width="13" className={styles.hrContainer}>
                    <div className={styles.hr} />
                  </Cell>
                )}
                {!!detailExtraFields && getCellData(detailExtraFields)}
              </>,
            ]
          : undefined,
    },
  ];

  return (
    <div ref={jumpToRef}>
      <CardList data={data} />
    </div>
  );
};

export const InspectionHeader = ({
  title,
  titleIcon,
  subtitle,
  summaryFields,
}: InspectionHeaderProps) => {
  return (
    <>
      <Cell width="13" className={styles.titleBlock}>
        {!!titleIcon && <div className={styles.titleIcon}>{titleIcon}</div>}
        <div className={styles.title}>
          {title}
          {!!subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      </Cell>
      {!!summaryFields.status && (
        <Cell
          width="13"
          className={classNames(
            styles.inspectionResultBlock,
            styles.fieldsContainer
          )}
        >
          <Cell width="3" className={styles.fieldTitle}>
            Inspection Result:
          </Cell>
          <Cell
            width="3"
            className={classNames(
              styles.fieldValue,
              styles[summaryFields.status?.toLowerCase() || '']
            )}
          >
            {summaryFields.status || ''}
            {!!summaryFields.statusCount && ` (${summaryFields.statusCount})`}
          </Cell>
          {!!summaryFields.onlineStatus && (
            <>
              <Cell width="3" className={styles.fieldTitle}>
                Status:
              </Cell>
              <Cell
                width="1"
                className={classNames(
                  styles.fieldValue,
                  styles[summaryFields.onlineStatus?.toLowerCase() || '']
                )}
              >
                {summaryFields.onlineStatus || ''}
                {getErrorOrWarningIconForField({
                  error: summaryFields.onlineStatusError,
                })}
              </Cell>
              <Cell width="2" className={styles.fieldValue}>
                {summaryFields.onlineStatusTime}
              </Cell>
            </>
          )}
        </Cell>
      )}
    </>
  );
};

export default InspectionTile;
