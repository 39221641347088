import React from 'react';
import {
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionStatus,
  InspectionJumpToRef,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  getDetailFields,
  getSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import InspectionTile from '../InspectionTile';
import { Site } from '@energybox/react-ui-library/dist/icons';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  determinePhaseDisplayAndSource,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';

type Props = {
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  jumpToRef: InspectionJumpToRef;
};

const SiteTotalTile: React.FC<Props> = ({ data, detailLevel, jumpToRef }) => {
  const summaryFields = getSummaryFields(data);

  const fields = [
    {
      name: 'Site Total Calculation',
      key: 'cal_method',
      transformValue: (value: SiteEnergyCalculationMode) =>
        value === SiteEnergyCalculationMode.MDP_MAINS
          ? 'MDP Main'
          : value === SiteEnergyCalculationMode.MDP_BREAKERS
          ? 'MDP Breakers'
          : value === SiteEnergyCalculationMode.DP_MAINS
          ? 'DP Main'
          : value === SiteEnergyCalculationMode.DP_BREAKERS
          ? 'DP Breakers'
          : value === SiteEnergyCalculationMode.FLAGGED_BREAKERS
          ? 'Flagged Breakers'
          : value,
    },
    {
      name: null,
      key: ' ',
      transformValue: () => '',
    },
    {
      name: 'Number of Breakers',
      key: 'num_breaker',
    },
  ];

  const columns: Columns<any>[] = [
    {
      header: 'Breaker',
      width: '10%',
      cellContent: ({ breaker_title }) => {
        return (
          <div
            style={{
              fontWeight: breaker_title === 'Total' ? 'bold' : 'normal',
            }}
          >
            {breaker_title || global.NOT_AVAILABLE}
          </div>
        );
      },
    },
    {
      header: 'Equipment',
      width: '15%',
      cellContent: ({ eq_title }) => {
        return <div>{eq_title || global.NOT_AVAILABLE}</div>;
      },
    },
    {
      header: 'Distribution Panel',
      width: '15%',
      cellContent: ({ panel_title }) => {
        return <div>{panel_title || global.NOT_AVAILABLE}</div>;
      },
    },
    {
      header: 'Phase',
      width: '5%',
      cellContent: ({ phase }) => {
        if (!phase) return <div>{global.NOT_AVAILABLE}</div>;
        const { config_phase, erms_phase, proposed_phase } = phase;
        const { phaseLabel } = determinePhaseDisplayAndSource(
          config_phase,
          erms_phase || proposed_phase
        );
        return <div>{phaseLabel || global.NOT_AVAILABLE}</div>;
      },
    },
    {
      header: 'Active Power (kW)',
      width: '8%',
      cellContent: ({ active_power, breaker_title }) => {
        return (
          <div
            style={{
              fontWeight: breaker_title === 'Total' ? 'bold' : 'normal',
            }}
          >
            {active_power
              ? formatDecimalValue(active_power / 1000, 2)
              : global.NOT_AVAILABLE}
          </div>
        );
      },
    },
    {
      header: 'Current (A)',
      width: '8%',
      cellContent: ({ current, breaker_title }) => {
        return (
          <div
            style={{
              fontWeight: breaker_title === 'Total' ? 'bold' : 'normal',
            }}
          >
            {current ? formatDecimalValue(current, 2) : global.NOT_AVAILABLE}
          </div>
        );
      },
    },
    {
      header: 'Pf',
      width: '8%',
      cellContent: ({ pf }) => {
        return (
          <div>{pf ? formatDecimalValue(pf, 2) : global.NOT_AVAILABLE}</div>
        );
      },
    },
  ];

  return (
    <InspectionTile
      title={'Site Total'}
      titleIcon={<Site variant="small" size="20" />}
      summaryFields={summaryFields}
      detailTable={{
        dataIsLoading: false,
        columns: columns,
        highlightAlternateRows: true,
        data: (data.lst_breaker as any)?.total
          ? [
              {
                breaker_title: 'Total',
                ...(data.lst_breaker as any)?.total,
              },
              ...(data.lst_breaker as any)?.breakers,
            ]
          : (data.lst_breaker as any)?.breakers || [],
      }}
      detailFields={getDetailFields(fields as any, data, detailLevel)}
      jumpToRef={jumpToRef}
    />
  );
};

export default SiteTotalTile;
