import {
  FileIcon,
  LongExtraLongSkeletonCell,
  MediumSkeletonCell,
  ShortSkeletonCell,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import { Download, Trash } from '@energybox/react-ui-library/dist/icons';
import {
  FileDetail,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import { filetypeMapper } from '@energybox/react-ui-library/dist/types/FileType';
import {
  determineTimeStringByTimezone,
  displayByte,
  genericTableSort,
  getFileTypeByName,
  SORT_IGNORED_VALUES,
  theme,
} from '@energybox/react-ui-library/dist/utils';
import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { fetchDownloadFile } from '../../../../../actions/files';
import { TableWrapper } from '../../../../../components/ui/Table';
import { useDeleteFile, useFiles } from '../../../../../hooks/files/useFile';
import { useSiteTimezone } from '../../../../../hooks/useSites';
import { ApplicationState } from '../../../../../reducers';
import { FileType } from '../../../../../types/files';
import styles from './UploadHistoryTable.module.css';
import localTheme from '../../../../../theme';
import DeleteModal from '../../../../../components/Modals/DeleteModal';

interface Props {
  siteId?: number;
}

const cellStyle = {
  verticalAlign: 'middle',
};

const UploadHistoryTable: React.FC<Props> = props => {
  const { siteId } = props;
  const ianaTimeZoneCode = useSiteTimezone(siteId);
  const locale = useSelector(({ app }: ApplicationState) => app.locale);

  const {
    fileToDelete,
    setFileToDelete,
    onDelete,
    isLoading: isDeleting,
    error: deleteError,
  } = useDeleteFile({ fileType: FileType.SITE_INSTALLATION_REPORT, siteId });

  const { files, isLoading } = useFiles({
    fileType: FileType.SITE_INSTALLATION_REPORT,
    siteId,
  });

  const viewFile = async (fileId: string, fileName: string) => {
    fetchDownloadFile(fileId, fileName);
  };

  const columns: Columns<FileDetail>[] = [
    {
      header: 'Document',
      width: '50%',
      cellContent: ({ fileName, fileType, fileId, fileLocation }) => (
        <Box
          display="flex"
          flexDirection="row"
          className={styles.cellContainer}
        >
          <Box alignSelf={'center'}>
            <FileIcon fileType={getFileTypeByName(fileLocation)} />
          </Box>

          <div className={styles.DocumentTitle}>{fileName}</div>
        </Box>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: FileDetail,
        b: FileDetail,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'filename',
        ]);
      },
      cellStyle,
    },
    {
      header: 'Size',
      width: '20%',
      cellContent: ({ fileSize }) => <div>{displayByte(fileSize)}</div>,
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: FileDetail,
        b: FileDetail,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'size',
        ]);
      },
      cellStyle,
    },

    {
      header: 'Timestamp',
      width: '20%',
      defaultSortDirection: SortDirection.DESC,
      isDefaultSort: true,
      cellContent: ({ createdAt }) => (
        <>
          {determineTimeStringByTimezone(
            new Date(createdAt),
            locale.dateTimeFormat,
            ianaTimeZoneCode,
            true
          )}
        </>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: FileDetail,
        b: FileDetail,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'createdAt',
        ]);
      },
      cellStyle,
    },
    {
      header: 'Download',
      width: '5%',
      cellContent: file => (
        <Box className={styles.buttonGroup}>
          <IconButton size={'small'} onClick={() => viewFile(file.fileId, file.fileName)}>
            <Download
              size={localTheme.size.icon.medium}
              color={theme.palette.primary.main}
            />
          </IconButton>
        </Box>
      ),
      skeletonCellContent: () => <div />,
      cellStyle,
    },
    {
      header: 'Actions',
      width: '5%',
      cellContent: file => (
        <Box className={styles.buttonGroup}>
          <IconButton
            size={'small'}
            onClick={() => setFileToDelete(file)}
            className={styles.trashIconButton}
          >
            <Trash />
          </IconButton>
        </Box>
      ),
      skeletonCellContent: () => <div />,
      cellStyle,
    },
  ];

  return (
    <>
      <div className={styles.cardTitle}>{`Installation Document History`}</div>
      <TableWrapper
        header={files.length > 0 ? `${files.length} Documents` : undefined}
      >
        <Table
          listView
          columns={columns}
          data={files}
          dataIsLoading={isLoading}
          skeletonRowCount={3}
        />
      </TableWrapper>
      {files.length <= 0 && <div className={styles.noData}>{`No History`}</div>}

      {fileToDelete && (
        <DeleteModal
          onClose={() => setFileToDelete(null)}
          onDelete={onDelete}
          apiError={deleteError}
          title={fileToDelete?.fileName ?? ''}
        />
      )}
    </>
  );
};

export default UploadHistoryTable;
